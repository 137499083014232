@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

:root {
  --primary: #3067f9;
  --secondary: #eaf0fe;
  --base-border-radius: 8px;
}

h1 {
  font-size: 32px;
  font-weight: 500;
}

h2 {
  font-size: 22px;
  font-weight: 500;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 1140px;
}
